body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-y: none;

  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;

  background-color: rgb(242, 242, 242);
  font-family: "Cormorant Garamond" !important;
}

html {
  margin: 0;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.fade-enter {
  opacity: 0.7;
}

.fade-enter.fade-enter-active {
  opacity: 1;

  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 900ms ease-in;
}

/*-------------------------------------------FRONT PAGE---------------------------------------*/

.frontPageContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;

  overflow: hidden;
  background-image: url("./images/cover.jpg");
  background-size: cover;
}

.background {
  z-index: 1;
  padding: 0;
  margin: 0;

  overflow: hidden;
}
.menuCenter {
  display: flex;
  justify-content: center;
}
.menuContainer {
  position: absolute;
  z-index: 2;

  margin-top: 40vh;
  margin-bottom: 10vh;

  color: white;

  text-shadow: black 7px 7px 9px;

  cursor: default;
}

.upperMenuContainer {
  display: flex;
  justify-content: space-evenly;

  font-size: 1.3vw;
  font-weight: 550;
}
.link {
  color: white;
  text-decoration: none;
}
.introButton {
  color: white;
  text-decoration: none;
  text-shadow: black 3px 3px 3.5px;
}
.introButton:hover {
  color: #9f3f1b;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.introName {
  font-size: 5vw;
  letter-spacing: 4px;
  word-spacing: 10px;
}
.socialCenter {
  display: flex;
  justify-content: center;
}
.socialContainer {
  display: inline-block;
  opacity: 0.85;

  max-width: 30%;
  min-width: 20.1%;

  margin-top: 90vh;

  position: absolute;
  z-index: 2;

  text-align: center;
  color: white;
  font-size: 2vw;
}

.email {
  font-weight: 600;
  font-size: 1vw;
  text-shadow: black 2.5px 2.5px 5px;
  cursor: default;
}

.fbIcon {
  max-width: 1.3vw;
  margin-right: 4%;
  cursor: pointer;
}
.instaIcon {
  max-width: 1.3vw;
  cursor: pointer;
}

/*------------------------------------------------About--------------------------------------*/
.aboutContainer {
  position: relative;
}
.banner {
  width: 100%;
  box-shadow: #4d4d4d 1px 1px 7px;
}
.navContainer {
  display: flex;
  justify-content: space-between;

  position: absolute;
  z-index: 2;

  width: 100%;
  margin-top: 3%;

  letter-spacing: 2px;

  text-shadow: black 3px 3px 3.5px;
}
.simonaLink {
  color: white;
  text-decoration: none;

  font-weight: lighter;
  font-size: 1.3vw;
  text-shadow: black 3px 3px 3.5px;

  width: 15%;
  margin-left: 6%;
}

.navMenu {
  display: flex;
  justify-content: space-evenly;
  font-size: 1.1vw;
  font-weight: 550;

  width: 9%;
  margin-right: 6%;
}
.navHomeArt:hover {
  color: #c83e13;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.bannerText {
  position: absolute;
  z-index: 2;

  width: 100%;
  margin-top: 13%;
  text-align: center;

  color: white;

  font-size: 2.5vw;
  font-weight: 500;
  letter-spacing: 2px;

  text-shadow: black 3px 3px 3.5px;
}

.bioWrapper {
  color: #5b5e60;
  font-size: 1.4vw;

  width: 65%;
  text-align: center;
  margin: 0 auto;
  padding-top: 12%;

  word-spacing: -1px;
  font-style: italic;
}
.aboutTitle {
  font-weight: 650;
  font-style: italic;
  word-spacing: -1px;
  margin-bottom: 6%;
  font-size: 1.7vw;
}
.aboutMid {
  margin-bottom: 3%;
}
.aboutReason {
  margin-bottom: 6%;
}
.aboutEnding1 {
  margin-bottom: 6%;
}
.background1 {
  display: block;
  width: 30vw;
  margin: 0 auto;
  margin-top: 7%;
  margin-bottom: 5%;

  box-shadow: #4d4d4d -2.5px 2.5px 9px;
  border-radius: 4px;
}
.bioTag {
  float: left;
  width: 10vw;
  margin-top: 7%;
  padding-bottom: 0.5%;
  margin-left: 2%;
  border-bottom: thin gray solid;
  color: #5b5e60;
  font-size: 1vw;
  font-style: italic;
}

/*------------------------------------------------Art--------------------------------------*/

.artContainer {
  position: relative;
}
.artBanner {
  object-fit: cover;
  width: 100%;

  box-shadow: #4d4d4d 1px 1px 7px;
}
.artContainer .navContainer {
  margin-top: 3%;
}
.artContainer .navHomeArt:hover {
  color: #9c1107;
}
.artContainer .navMenu {
  justify-content: space-between;
}
.modalContainer {
  width: 30%;
  margin: 0 auto;

  margin-top: 15%;
}
.modalContainer img {
  box-shadow: #4d4d4d 2.5px 2.5px 9px;
  border-radius: 4px;
}

.__react_modal_image__header {
  text-align: center;
  word-spacing: 4px;
}
.__react_modal_image__modal_content img {
  top: 52% !important;
}
.__react_modal_image__medium_img {
  height: 90%;
}
.smallModal {
  width: 50%;
  margin-left: 25%;
  margin-top: 9%;
}
.smallModalWrapper .__react_modal_image__header {
  background-color: rgba(0, 0, 0, 0) !important;
}
.bannerContainer {
  bottom: 0;
  margin: 0;
  padding: 0;
  height: 6vw;
  margin-top: 7.5%;
}
.bannerBottom {
  display: block;
  margin: 0;
  padding: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  box-shadow: #4d4d4d 1px -1px 7px;
}

/*------------------------------------------------BURGER MENU---------------------------------------*/
/* Position and sizing of burger button */
.bm-burger-button {
  width: 7.5vw;
  height: 6vw;
  left: 85%;
  top: 7vw;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  height: 10% !important;
  background: #e6e6e6;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  transition: all 0.1s ease 0.2s !important;
}

/* General sidebar styles */
.bm-menu {
  background-image: url("./images/cover.jpg");
  background-position: 50% 20%;

  background-size: cover;

  font-size: 10vw;
  position: relative;

  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;

  margin: 0;
  position: absolute;
  top: 20%;

  text-align: center;
  text-shadow: black 4px 4px 9px;
  width: 100%;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-top: 30%;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/*------------------------------------------------Responsive Design---------------------------------------*/

@media screen and (max-width: 1024px) {
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .bm-burger-button {
    position: absolute;

    display: unset;
  }
  .frontPageContainer {
    background-position: 20% 70%;
  }
  .background {
    width: 350%;
  }
  .upperMenuContainer {
    font-size: 4vw;
    margin-bottom: 3%;
  }

  .introButton {
    text-shadow: black 7px 7px 9px;

    font-weight: lighter;
  }
  .introName {
    font-size: 10vw;
    letter-spacing: 2px;
  }
  .email {
    font-size: 4.5vw;
    margin-bottom: 7%;
  }
  .fbIcon {
    max-width: 6vw;
    margin-right: 7%;
  }
  .instaIcon {
    max-width: 6vw;
  }

  /*-----------------------------Nav Menu & Banners------------------------*/
  .simonaLink {
    display: none;
  }
  .navContainer {
    justify-content: center;
    font-weight: 400;
    display: none;
  }
  .navMenu {
    font-size: 6vw;
    width: 65%;
    margin: 0;
    margin-top: 3.2%;
  }
  .aboutContainer .navMenu {
    justify-content: space-between;
  }
  .bannerText {
    display: none;
  }
  .banner {
    height: 20vw;
  }
  /*------------------------------------------------Art--------------------------------------*/

  .artBanner {
    height: 20vw;
  }
  .modalContainer {
    width: 65vw;
  }
  .bannerContainer {
    height: 10vw;
  }
  .OPEN {
    pointer-events: none;
  }
  .__react_modal_image__medium_img {
    height: unset;
    max-height: 65vh !important;
    touch-action: pinch-zoom;
  }
  .__react_modal_image__caption {
    font-size: 4.5vw !important;
    padding-top: 0% !important;
  }
  .__react_modal_image__header {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .bigModalWrapper .__react_modal_image__icon_menu a {
    line-height: 10px !important;
  }
  /*------------------------------------------------ABOUT--------------------------------------*/
  .bioTag {
    float: none;
    margin: 0 auto;
    width: 45vw;
    margin-top: 15%;
    padding-bottom: 0.5%;

    text-align: center;
    font-size: 6vw;
  }
  .bioWrapper {
    font-size: 4vw;
    width: 75%;
    padding-top: 15%;

    word-spacing: -1px;
    font-style: italic;
  }

  .aboutTitle {
    font-weight: 650;
    font-style: italic;
    word-spacing: -1px;
    margin-bottom: 9%;
    font-size: 6vw;
  }
  .aboutMid {
    margin-bottom: 3%;
  }
  .aboutReason {
    margin-bottom: 6%;
  }
  .aboutEnding1 {
    margin-bottom: 6%;
  }
  .background1 {
    display: block;
    width: 60vw;
    margin: 0 auto;
    margin-top: 7%;
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 1024px) {
  .upperMenuContainer {
    font-size: 6vw;
    margin-bottom: 3%;
  }
  .introButton {
    text-shadow: black 7px 7px 9px;
  }
  .introName {
    font-size: 10vw;
  }
  .socialContainer {
    margin-top: 80vh;
    min-width: 60%;
  }
  .email {
    font-size: 4.5vw;
    margin-bottom: 7%;
  }
  .fbIcon {
    max-width: 6vw;
    margin-right: 7%;
  }
  .instaIcon {
    max-width: 6vw;
  }
}
